main{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.error-title-box{
    text-align: center; 
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
}

